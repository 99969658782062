import Vue from 'vue'

const CACHE_LIFETIME = 3600
const state = {
    channelItems: {
        append: false,
        loading: true,
        data: [],
    },
    channels: {
        append: false,
        loading: true,
        data: [],
    },
    seriesItems: {
        loading: true,
        data: [],
    },
    series: {
        loading: true,
        data: [],
    },
    genres: {
        append: false,
        loading: true,
        data: [],
    },
    genreItems: {
        append: false,
        loading: true,
        data: [],
    },
    recommendations: {
        append: false,
        loading: true,
        data: [],
    },
    movies: {
        append: false,
        loading: true,
        data: [],
        prev_count: -1,
        created_at: null,
        expired_at: null,
    },
    latest: {
        append: false,
        loading: true,
        data: [],
    },
}
const getters = {
    channelItems: (state, getters, rootState, rootGetters) => {
        if (state.channelItems.data.length) {
            return state.channelItems.data
        } else return []
    },
    genreItems: (state, getters, rootState, rootGetters) => {
        if (state.genreItems.data.length) {
            return state.genreItems.data
        } else return []
    },
    seriesItems: (state, getters, rootState, rootGetters) => {
        if (state.seriesItems.data.length) {
            return state.seriesItems.data
        } else return []
    },
    genres: (state, getters, rootState, rootGetters) => {
        if (state.genres.data.length) {
            return state.genres.data
        } else return []
    },
    series: (state, getters, rootState, rootGetters) => {
        if (state.series.data.length) {
            return state.series.data
        } else return []
    },
    channels: (state, getters, rootState, rootGetters) => {
        if (state.channels.data.length) {
            return state.channels.data
        } else return []
    },
    recommendations: (state, getters, rootState, rootGetters) => {
        if (state.recommendations.data.length) {
            return state.recommendations.data
        } else return []
    },
    movies: (state, getters, rootState, rootGetters) => {
        if (state.movies.data.length) {
            return state.movies.data
        } else return []
    },
    latest: (state, getters, rootState, rootGetters) => {
        if (state.latest.data.length) {
            return state.latest.data
        } else return []
    },
}
const actions = {
    async series(context, payload) {
        const scope = 'series'
        context.state.series.loading = true
        let query = '?api_token=' + context.rootState.auth.token
        return new Promise((resolve, reject) => {
            if (
                dayjs().isBetween(
                    context.state[scope].created_at,
                    context.state[scope].expired_at
                )
            ) {
                // cached
                resolve()
            } else {
                context.state[scope].loading = true
                axios
                    .get('/medialib/series' + query)
                    .then((response) => {
                        context.commit('UPDATE_PROP', {
                            prop: scope,
                            value: response.data,
                        })
                        resolve()
                    })
                    .catch((e) => {
                        reject(e)
                    })
            }
        })
    },
    async channels(context, payload) {
        const scope = 'channels'
        let query = '?api_token=' + context.rootState.auth.token
        return new Promise((resolve, reject) => {
            if (
                dayjs().isBetween(
                    context.state[scope].created_at,
                    context.state[scope].expired_at
                )
            ) {
                // cached
                resolve()
            } else {
                context.state[scope].loading = true
                axios
                    .get('/medialib/channels' + query)
                    .then((response) => {
                        context.commit('UPDATE_PROP', {
                            prop: scope,
                            value: response.data,
                        })
                        resolve()
                    })
                    .catch((e) => {
                        reject(e)
                    })
            }
        })
    },
    async genres(context, payload) {
        const scope = 'genres'
        let query = '?api_token=' + context.rootState.auth.token
        return new Promise((resolve, reject) => {
            if (
                dayjs().isBetween(
                    context.state[scope].created_at,
                    context.state[scope].expired_at
                )
            ) {
                // cached
                resolve()
            } else {
                context.state[scope].loading = true
                axios
                    .get('/medialib/genres' + query)
                    .then((response) => {
                        context.commit('UPDATE_PROP', {
                            prop: scope,
                            value: response.data,
                        })
                        resolve()
                    })
                    .catch((e) => {
                        reject(e)
                    })
            }
        })
    },
    async channelItems(context, payload) {
        const scope = 'channelItems'
        context.state[scope].append = false
        let query = '?api_token=' + context.rootState.auth.token
        if (payload !== undefined && payload.offset > 0) {
            query += '&offset=' + payload.offset
            context.state[scope].append = true
        }
        return new Promise((resolve, reject) => {
            context.state[scope].loading = true
            axios
                .get('/medialib/channels/' + payload.channelId + query)
                .then((response) => {
                    context.commit('UPDATE_PROP', {
                        prop: scope,
                        value: response.data,
                    })
                    resolve()
                })
                .catch((e) => {
                    reject(e)
                })
        })
    },
    async genreItems(context, payload) {
        const scope = 'genreItems'
        context.state[scope].append = false
        let query = '?api_token=' + context.rootState.auth.token

        if (payload !== undefined && payload.offset > 0) {
            query += '&offset=' + payload.offset
            context.state[scope].append = true
        }
        return new Promise((resolve, reject) => {
            context.state[scope].loading = true
            axios
                .get('/medialib/genres/' + payload.genreId + query)
                .then((response) => {
                    context.commit('UPDATE_PROP', {
                        prop: scope,
                        value: response.data,
                    })
                    resolve()
                })
                .catch((e) => {
                    reject(e)
                })
        })
    },
    async seriesItems(context, payload) {
        let query = '?api_token=' + context.rootState.auth.token
        return new Promise((resolve, reject) => {
            context.state[scope].loading = true
            axios
                .get('/medialib/series/' + payload.seriesId + query)
                .then((response) => {
                    context.commit('UPDATE_PROP', {
                        prop: 'seriesItems',
                        value: response.data,
                    })
                    resolve()
                })
                .catch((e) => {
                    reject(e)
                })
        })
    },
    async recommendations(context, payload) {
        const scope = 'recommendations'
        context.state[scope].append = false

        let query = '?api_token=' + context.rootState.auth.token

        if (payload !== undefined && payload.offset !== undefined) {
            query += '&offset=' + payload.offset
            context.state[scope].append = true
        }
        return new Promise((resolve, reject) => {
            if (
                dayjs().isBetween(
                    context.state[scope].created_at,
                    context.state[scope].expired_at
                )
            ) {
                // cached
                resolve()
            } else {
                context.state[scope].loading = true
                axios
                    .get('/medialib/' + scope + query)
                    .then((response) => {
                        context.commit('UPDATE_PROP', {
                            prop: scope,
                            value: response.data,
                        })
                        resolve()
                    })
                    .catch((e) => {
                        reject(e)
                    })
            }
        })
    },
    async movies(context, payload) {
        const scope = 'movies'
        context.state[scope].append = false

        let query = '?api_token=' + context.rootState.auth.token
        if (
            payload !== undefined &&
            payload.offset !== undefined &&
            payload.offset > 0
        ) {
            query += '&offset=' + payload.offset
            context.state[scope].append = true
        }
        if (payload !== undefined && payload.sortBy !== undefined) {
            query += '&sort_by=' + payload.sortBy
        }
        return new Promise((resolve, reject) => {
            const lazyload =
                context.state[scope].append &&
                context.state[scope].prev_count !=
                    context.state[scope].data.length
            context.state[scope].data.length
            if (
                dayjs().isBetween(
                    context.state[scope].created_at,
                    context.state[scope].expired_at
                ) &&
                !lazyload
            ) {
                // cached
                resolve()
            } else {
                context.state[scope].loading = true
                context.state[scope].prev_count =
                    context.state[scope].data.length
                axios
                    .get('/medialib/' + scope + query)
                    .then((response) => {
                        context.commit('UPDATE_PROP', {
                            prop: scope,
                            value: response.data,
                        })

                        resolve()
                    })
                    .catch((e) => {
                        reject(e)
                    })
            }
        })
    },
    async latest(context, payload) {
        const scope = 'latest'
        context.state[scope].append = false

        let query = '?api_token=' + context.rootState.auth.token

        if (payload !== undefined && payload.offset !== undefined) {
            query += '&offset=' + payload.offset
            context.state[scope].append = true
        }
        return new Promise((resolve, reject) => {
            if (
                dayjs().isBetween(
                    context.state[scope].created_at,
                    context.state[scope].expired_at
                )
            ) {
                // cached
                resolve()
            } else {
                context.state[scope].loading = true
                axios
                    .get('/medialib/' + scope + query)
                    .then((response) => {
                        context.commit('UPDATE_PROP', {
                            prop: scope,
                            value: response.data,
                        })
                        resolve()
                    })
                    .catch((e) => {
                        reject(e)
                    })
            }
        })
    },
}
const mutations = {
    UPDATE_PROP(state, payload) {
        if (state[payload.prop].append) {
            state[payload.prop].data = [
                ...state[payload.prop].data,
                ...payload.value,
            ]
        } else {
            state[payload.prop].created_at = dayjs().toString()
            state[payload.prop].expired_at = dayjs()
                .add(CACHE_LIFETIME, 's')
                .toString()
            state[payload.prop].data = payload.value
        }
        state[payload.prop].loading = false
    },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
