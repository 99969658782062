<template>
    <div>
        <div
            class="
                f
                d-flex
                align-items-center
                justify-content-between
                flex-nowrap
                m-0
                my-2
                p-2
                shadow-sm
                bg-white
            "
            @click="handleClick(item)"
        >
            <div class="flex-fill" style="font-size: 115%">
                <div class="d-flex align-items-center">
                    <div>
                        <img
                            :src="item.image_url"
                            class="rounded"
                            style="max-width: 60px"
                        />
                    </div>
                    <div class="ml-2 flex-fill">
                        <div class="small">
                            <slot name="header">
                                <span>
                                    {{
                                        dayjs()
                                            .subtract(5, 'minute')
                                            .format('HH:mm')
                                    }}
                                    -
                                    {{
                                        dayjs(
                                            `${dayjs().format(
                                                'YYYY-MM-DD HH'
                                            )}:00:00`
                                        )
                                            .add(item.runtime, 'minute')
                                            .format('HH:mm')
                                    }}
                                </span>
                                <span
                                    class="badge badge-primary text-white ml-1"
                                >
                                    <font-awesome-icon
                                        :icon="['fas', 'dot-circle']"
                                    />
                                    LIVE
                                </span>
                            </slot>
                        </div>
                        <strong class="d-block text-gray-dark"
                            >Spielfilm: {{ item.title }}</strong
                        >
                        <div class="progress" style="height: 3px">
                            <div class="progress-bar" style="width: 3%"></div>
                        </div>
                        <small>läuft noch {{ item.runtime }} Minuten</small>
                    </div>
                    <div class="ml-2 p-1">
                        <font-awesome-icon :icon="['fas', 'chevron-right']" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import dayjs from 'dayjs'
export default {
    name: 'JoynItem',
    props: ['item', 'source'],
    data: () => ({
        dayjs,
    }),
    methods: {
        handleClick(item) {
            this.startJoyn(item, this.source)
        },
    },
}
//"js": 'setTimeout(()=>{document.querySelector("#__next > cmp-banner").shadowRoot.querySelector("div > div.banner__overlay > div > cmp-dialog").shadowRoot.querySelector("div > div.dialog__actions.dialog__actions--with-footer > div.dialog__action.dialog__action--primary > cmp-button").shadowRoot.querySelector("button").click();},1e4);',
</script>
