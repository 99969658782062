<template>
    <b-overlay :show="loading" rounded="sm">
        <div
            class="d-flex h-100 shadow-sm bg-white"
            :class="{
                'm-0 my-2 ': layout == 0 || layout == 3,
                'm-1': layout == 1 || layout == 2,
            }"
            @click="handleClick(item)"
        >
            <div class="w-100" v-if="layout == 0">
                <image-item :index="index" :source="item.image_url" class="w-100" />
                <div class="p-2">
                    <h6 class="my-0 page-title">
                        {{ item.title }}
                        <span v-if="item.series_name != null">
                            - {{ item.series_name }}</span
                        >
                        | {{ item.station }}
                    </h6>
                    <span class="text-muted"
                        >vom {{ dayjs(item.published_at).format('DD.MM.YYYY') }} | Dauer: 
                        {{ item.duration }} Minuten</span
                    >
                </div>
            </div>
            <div style="width: 180px" v-if="layout == 1">
                <image-item :index="index" :source="item.image_url" class="w-100" />
                <div class="p-2">
                    <h6 class="my-0 page-title lines-4">{{ item.title }}</h6>
                    <span class="text-muted">Dauer: {{ item.duration }} Minuten</span>
                </div>
            </div>
            <div style="width: 180px" v-if="layout == 2">
                <image-item
                    :index="index"
                    :source="item.image_url"
                    class="w-100"
                    height="250px"
                />
                <div class="p-2">
                    <h6 class="my-0 page-title lines-4">{{ item.title }}</h6>
                    <span class="text-muted">
                       Dauer: {{ item.duration }} Minuten <br />
                        <small v-if="item.genres">Genre: {{
                            item.genres.split(',').slice(0, 2).join(', ')
                        }}</small></span
                    >
                </div>
            </div>
            <div class="w-100" v-if="layout == 3">
                <image-item :index="index" :source="item.image_url" class="w-100" />
                <div class="p-2">
                    <h6 class="my-0 page-title">
                        {{ item.title }}
                        <span v-if="item.series_name != null">
                            - {{ item.series_name }}</span
                        >
                    </h6>
                    <span class="text-muted">
                        <span v-if="item.genres">Genre: {{ item.genres.split(',').slice(0, 2).join(', ') }} |</span>
                        Dauer: {{ item.duration }} Minuten
                    </span>
                </div>
            </div>            
            <ad v-model="ad.show"  :response="ad.response" />
        </div>
    </b-overlay>
</template>
<script>
import ImageItem from '../../components/ImageItem'
import Ad from '../../components/Ad'
export default {
    name: 'Item',
    props: ['item', 'layout', 'index'],
    components: {
        ImageItem,
        Ad,
    },
    data: function() {
        return {
            loading: false,
            dayjs,
            ad: {
                show: false,
                response: {},
            },
        }
    },
    computed: {},
    methods: {
        handleClick() {
            this.loading = true
            const token = this.$store.state.auth.token

            axios
                .post(
                    '/activity/start-media-library-item',
                    {
                        id: this.item.id,
                        ad_available:
                            localStorage.getItem('ad_available') == 'true',
                    },
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                )
                .then(response => {
                    if (response.data['params'].show_ad) {
                        localStorage.setItem('last_ad_at', dayjs())
                        localStorage.setItem('ad_loaded', 0)
                        this.ad.show = true
                        this.ad.response = response
                    } else {
                        this.nativeBridge(
                            response.data['fn'],
                            response.data['params']
                        )
                    }
                    this.loading = false
                })
                .catch(e => {
                    this.loading = false
                    this.handleAxiosError(e)
                })
        },
    },
    mounted() {},
}
</script>
