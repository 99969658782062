<template>
    <div>
        <loading v-if="loading" />
        <div v-else>
            <h5 class="page-title mb-0">Empfehlungen</h5>
            <div v-for="(item, index) in items" :key="'a-' + index">
                <media-library-item :item="item" :layout="0" :index="index" />
            </div>
            <loading class="mt-2" v-if="appendLoading" />
        </div>
    </div>
</template>
<script>
import MediaLibraryRecommendations from '../../../views/MediaLibraryRecommendations'
export default {
    ...MediaLibraryRecommendations
}
</script>