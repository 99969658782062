import Vue from 'vue'

const CACHE_LIFETIME = 3600

const state = {
    search: [],
    all: {
        loading: true,
        created_at: null,
        data: [],
        expired_at: null,        
    },
    first: {},
    broadcasts: {
        key: null,
        loading: true,
        created_at: null,
        data: [],
        expired_at: null,
    },
    // multiple dates
    recommendations: {
        selector: null,
        loading: true,
        data: [],
    },
    c: 0,
    init: false,
}
const getters = {
    search: (state, getters, rootState, rootGetters) => {
        if (state.search.length) {
            return state.search
        } else return []
    },
    series: (state, getters, rootState, rootGetters) => {
        if (state.all.data.length) {
            return state.all.data
        } else return []
    },
    first: (state, getters, rootState, rootGetters) => {
        if (Object.keys(state.first).length) {
            return state.first
        } else return {}
    },
    broadcasts: (state, getters, rootState, rootGetters) => {
        if (Object.keys(state.first).length && state.first.broadcasts.length) {
            return state.first.broadcasts
        } else return []
    },
    recommendations: (state, getters, rootState, rootGetters) => {
        const series = rootGetters['series/series']
        const seriesInit = !rootState.series.all.loading

        console.log(state.recommendations.data, state.recommendations.data.length, seriesInit)
        if (state.recommendations.data.length && seriesInit) {

            
            let s = state.recommendations.data[0].data
            if(state.recommendations.selector !== null) {
                s = state.recommendations.data.find(item => item.date == state.recommendations.selector)
                if(s !== undefined) {
                    s = s.data 
                } else {
                    s = []
                }
            }
            console.log(s)
            return s
                .map(item => {
                    item.series = series.find(
                        series => series.series_id == item.series_id
                    )
                    if (item.series === undefined) {
                        item.series = null
                    }

                    item.running = dayjs().isBetween(item.bc_start, item.bc_end)
                    item.c = state.c
                    return item
                })
                .filter(item => dayjs().isBefore(item.bc_end))
        } else return []
    },
    recommendationsGroupedByDate: (state, getters, rootState, rootGetters) => {
        if (getters.recommendations.length) {
            const groups = getters.recommendations.reduce((groups, item) => {
                const date = dayjs(item.bc_start).format('YYYY-MM-DD')
                if (!groups[date]) {
                    groups[date] = []
                }
                groups[date].push(item)
                return groups
            }, {})

            let groupArrays = Object.keys(groups).map(date => {
                return {
                    date,
                    items: _.sortBy(groups[date], [
                        function(o) {
                            return o.bc_start
                        },
                    ]),
                }
            })

            return _.sortBy(groupArrays, [
                function(o) {
                    return o.date
                },
            ])
        } else return []
    },

    upcomingBroadcasts: (state, getters, rootState, rootGetters) => {
        if (state.broadcasts.data.length) {
            return state.broadcasts.data
                .map(item => {
                    item.running = dayjs().isBetween(item.bc_start, item.bc_end)
                    item.c = state.c
                    return item
                })
                .filter(item => dayjs().isBefore(item.bc_end))
        } else return []
    },
    groupedByDate: (state, getters, rootState, rootGetters) => {
        if (getters.upcomingBroadcasts) {
            const groups = getters.upcomingBroadcasts.reduce((groups, item) => {
                const date = dayjs(item.bc_start).format('YYYY-MM-DD')
                if (!groups[date]) {
                    groups[date] = []
                }
                groups[date].push(item)
                return groups
            }, {})

            let groupArrays = Object.keys(groups).map(date => {
                return {
                    date,
                    items: _.sortBy(groups[date], [
                        function(o) {
                            return o.bc_start
                        },
                    ]),
                }
            })

            return _.sortBy(groupArrays, [
                function(o) {
                    return o.date
                },
            ])
        } else return []
    },
}
const actions = {
    async search(context, payload) {
        return new Promise((resolve, reject) => {
            const token = context.rootState.auth.token
            const query =
                '?api_token=' + token + '&term=' + payload.data.keyword

            axios
                .get('/series/search' + query, {
                    cancelToken: payload.options.cancelToken,
                })
                .then(response => {
                    context.commit('SET_SEARCH_RESULTS', response.data)
                    resolve()
                })
                .catch(e => {
                    if (axios.isCancel(e)) {
                        resolve()
                    } else {
                        reject(e)
                    }
                })
        })
    },
    async show(context, payload) {
        return new Promise((resolve, reject) => {
            const token = context.rootState.auth.token
            const query = '?api_token=' + token

            axios
                .get('/series/' + payload.data.series_id + query)
                .then(response => {
                    response.data.time_groups = response.data.time_groups.map(
                        item => {
                            return {
                                value: item,
                                loading: false,
                            }
                        }
                    )

                    context.commit('UPDATE_PROP', {
                        prop: 'first',
                        value: response.data,
                    })
                    resolve()
                })
                .catch(e => {
                    reject(e)
                })
        })
    },
    async update(context, payload) {
        const token = context.rootState.auth.token
        return new Promise((resolve, reject) => {
            axios
                .put('/series/' + payload.data.id, payload.data.update, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then(response => {
                    const idx = context.state.all.data.findIndex(
                        item => item.series_id == response.data.series_id
                    )
                    Object.keys(payload.data.update).forEach(item => {
                        Vue.set(
                            context.state.all.data[idx],
                            item,
                            response.data[item]
                        )
                    })
                    resolve()
                })
                .catch(e => {
                    reject(e)
                })
        })
    },
    async fetch(context, payload) {
        const scope = 'all'
        let query = '?api_token=' + context.rootState.auth.token
        if (payload.broadcasts) {
            query += '&broadcasts=1'
        }
        if (payload.v) {
            query += '&v=' + payload.v
        }
        //const qs = query.forEach(item => item.)
        return new Promise((resolve, reject) => {
            if(dayjs().isBetween(context.state[scope].created_at, context.state[scope].expired_at)) {
                // cached
                resolve()
            } else {
                context.state[scope].loading = true
                axios
                    .get('/series' + query)
                    .then(response => {
                        context.commit('UPDATE_PROP2', {
                            prop: scope,
                            value: response.data,
                        })
                        resolve()
                    })
                    .catch(e => {
                        reject(e)
                    })
            }
        })
    },
    sync(context, payload) {
        return new Promise((resolve, reject) => {
            const token = context.rootState.auth.token
            axios
                .post('/series', payload.data, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then(response => {
                    context.commit('UPDATE_PROP2', {
                        prop: 'all',
                        value: response.data,
                    })
                    resolve()
                })
                .catch(e => {
                    reject(e)
                })
        })
    },
    async destroy(context, payload) {
        return new Promise((resolve, reject) => {
            const token = context.rootState.auth.token
            axios
                .delete('/bookmarks/' + payload.data.id, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then(response => {
                    context.commit('REMOVE_SERIES', payload.data)
                    resolve()
                })
                .catch(e => {
                    reject(e)
                })
        })
    },    
    async broadcasts(context, payload) {
        const scope = 'broadcasts'
        let query = '?api_token=' + context.rootState.auth.token
        if (payload !== undefined && payload.today) {
            query += '&today'
        }

        const key = '/series/broadcasts' + query
        return new Promise((resolve, reject) => {
            if(context.state[scope].key == key && dayjs().isBetween(context.state[scope].created_at, context.state[scope].expired_at)) {
                // cached
                resolve()
            } else {        
                context.state[scope].loading = true
                axios
                    .get(key)
                    .then(response => {
                        context.commit('UPDATE_PROP2', {
                            prop: scope,
                            value: response.data,
                        })
                        context.state[scope].key = key
                        resolve()
                    })
                    .catch(e => {
                        reject(e)
                    })
            }
        })
    },    
    async recommendations(context, payload) {
        console.log("jonas test");
        const scope = 'recommendations'
        let query = '?api_token=' + context.rootState.auth.token
        let date = null
        if (payload !== undefined && payload.today) {
            query += '&today'
        }
        if (payload !== undefined && payload.date && payload.date !== null) {
            query += '&date=' + payload.date
            date = payload.date
        }
        console.log("jonas test");
        return new Promise((resolve, reject) => {
            let dc = context.state[scope].data.find(item => item.date == date)

            console.log("test"+ dc)
            //console.log(context.state[scope].selector === null && context.state[scope].data[0] && dayjs().isBetween(context.state[scope].data[0].created_at, context.state[scope].data[0].expired_at), context.state[scope].selector !== null && dc !== undefined && dayjs().isBetween(dc.created_at, dc.expired_at))
            if(
                (date === null && context.state[scope].data[0] && dayjs().isBetween(context.state[scope].data[0].created_at, context.state[scope].data[0].expired_at)) ||
                (date !== null && dc !== undefined && dayjs().isBetween(dc.created_at, dc.expired_at))
            ) {
                // cached
                resolve()
            } else {
                context.state[scope].loading = true
                axios
                    .get('/recommendations' + query)
                    .then(response => {
                        context.commit('UPDATE_PROP3', {
                            prop: scope,
                            value: response.data,
                        })
                        resolve()
                    })
                    .catch(e => {
                        reject(e)
                    })
            }
        })
    },
}
const mutations = {
    SET_SEARCH_RESULTS(state, search) {
        Vue.set(state, 'search', search)
    },
    SET_SERIES(state, series) {
        Vue.set(state, 'all', series)
    },
    REMOVE_SERIES(state, series) {
        state.all.splice(state.all.indexOf(series), 1)
    },
    UPDATE_PROP(state, payload) {
        state[payload.prop] = payload.value
    },
    UPDATE_PROP2(state, payload) {
        state[payload.prop].created_at = dayjs().toString()
        state[payload.prop].expired_at = dayjs().add(CACHE_LIFETIME, 's').toString()
        state[payload.prop].data = payload.value
        state[payload.prop].loading = false
    },
    UPDATE_PROP3(state, payload) {
        if(!payload.value.length) {
            state[payload.prop].loading = false
            return;
        }
        let date = payload.value[0].date
        if(state[payload.prop].data.find(item => item.date == date) === undefined) {
            state[payload.prop].data.push({
                date,
                created_at:  dayjs().toString(),
                expired_at: dayjs().add(CACHE_LIFETIME, 's').toString(),
                data: payload.value
            }) 
        }
        state[payload.prop].loading = false
    },    
    INCREMENT_COUNTER(state) {
        Vue.set(state, 'c', state.c + 1)
    },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
