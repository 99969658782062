import { mapGetters } from 'vuex'
import Broadcast from '../components/Broadcast'
import Loading from '../components/Loading'

export default {
    components: {
        Broadcast,
        Loading,
    },
    data: function() {
        return {
            search: {
                keyword: '',
                items: [],
                loading: false,
                request: null,
            },
            selectedStation: null,
            selectedDate: null,
        }
    },
    watch: {
        'search.keyword': {
            handler() {
                if (this.search.loading) this.cancelRequest()
                if (this.search.keyword.length) {
                    this.search.loading = true
                    this.debSearch()
                } else {
                    this.$store.commit('program/UPDATE_PROP', {
                        prop: 'search',
                        value: [],
                    })
                }
            },
        },
        $route: {
            handler() {
                //this.search.keyword = ''
            },
        },
    },
    computed: {
        ...mapGetters('program', {
            searchResults: 'search',
        }),
        loading() {
            return (
                this.$store.state.program.loading ||
                this.$store.state.series.all.loading
            )
        },
    },
    methods: {
        onKeyUp() {
            if (this.search.loading) this.cancelRequest()
            if (this.search.keyword.length >= 2) {
                this.debSearch()
            } else {
                this.searchResults = []
            }
        },
        cancelRequest() {
            this.search.request.cancel()
        },
        searchProgram() {
            const axiosSource = axios.CancelToken.source()
            this.search.request = { cancel: axiosSource.cancel }
            this.search.loading = true
            this.$store
                .dispatch('program/search', {
                    data: {
                        keyword: this.search.keyword,
                    },
                    options: {
                        cancelToken: axiosSource.token,
                    },
                })
                .catch(e => {
                    this.handleAxiosError(e)
                })
                .then(() => {
                    this.search.loading = false
                })
        },
        fetchProgram() {
            this.$store
                .dispatch('program/fetch', {
                    data: {
                        station: null,
                        date: null,
                    },
                })
                .catch(e => {
                    this.handleAxiosError(e)
                })
        },
        fetchSeries() {
            this.$store
                .dispatch('series/fetch', {
                    v: this.$router.currentRoute.query.v
                })
                .catch(e => {
                    this.handleAxiosError(e)
                })
        },
    },
    mounted() {
        this.fetchSeries()
        this.fetchProgram()
        this.$refs.search.focus()
    },
    created() {
        window.onscroll = () => {
            let header = document.getElementById('stations')
            if (header) {
                let sticky = header.offsetTop
                if (window.pageYOffset > sticky) {
                    header.classList.add('sticky')
                } else {
                    header.classList.remove('sticky')
                }
            }
        }
        this.debSearch = _.debounce(this.searchProgram, 250)
    },
}