import { mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import Broadcast from '../components/Broadcast'
import Loading from '../components/Loading'

const getCountdown = start => {
    const m = Math.ceil(dayjs(start).diff(dayjs(), 'minutes', true))

    const MINUTE = 1
    const HOUR = 60
    const DAY = HOUR * 24

    const days = Math.floor(m / DAY)
    const hDiff = m % DAY
    const hours = Math.floor(hDiff / HOUR)
    const mDiff = hDiff % HOUR
    const minutes = Math.floor(mDiff / MINUTE)

    return [days, hours, minutes]
}

export default {
    components: {
        Broadcast,
        draggable,
        Loading
    },
    data: function () {
        return {
            dayjs,
            dragging: false,
            loading: true,
            modals: {
                stream_source: {
                    show: false,
                    selected: {},
                    dirty: false,
                },
                favorites: {
                    show: false,
                    selected: [],
                    collapsed: true,
                    hide: false,
                    editMode: false,
                    search: {
                        keyword: '',
                        loading: false,
                    },
                },
                station: {
                    show: false,
                    selected: {},
                    slice: 3,
                },
                add_series: {
                    show: false,
                    selected: [],
                    collapsed: true,
                    editMode: false,
                    hide: false,
                    search: {
                        keyword: '',
                        loading: false,
                        request: null,
                    },
                },
                series: {
                    show: false,
                    all: [],
                    selected: {},
                    broadcasts: [],
                    loading: false,
                },
                google: {
                    show: false,
                },
                ad: {
                    show: false,
                    countDown: 3,
                    response: {},
                },
            },
            items: [],
        }
    },
    watch: {
        favorites: {
            deep: true,
            handler() {
                this.modals.favorites.selected = _.cloneDeep(this.favorites)
            },
        },
        userSeries: {
            deep: true,
            handler() {
                this.modals.add_series.selected = _.cloneDeep(this.userSeries)
            },
        },
        'modals.add_series.search.keyword': {
            handler() {
                if (this.modals.add_series.search.loading)
                    this.cancelSearchSeriesRequest()
                if (this.modals.add_series.search.keyword.length) {
                    this.modals.add_series.search.loading = true
                    this.debSearchSeries()
                } else {
                    this.modals.add_series.hide = false
                    this.modals.add_series.search.loading = false
                    this.$store.commit('series/UPDATE_PROP', {
                        prop: 'search',
                        value: [],
                    })
                }
            },
        },
        'modals.favorites.search.keyword': {
            handler() {
                if (!this.modals.favorites.search.keyword) {
                    this.modals.favorites.hide = false
                }
            },
        },
    },
    computed: {
        stars() {
            if (
                Object.keys(this.activeSeries).length &&
                this.activeSeries.series_rating_value
            ) {
                const x1 = 1
                const y1 = 10
                const x2 = 1
                const y2 = 5
                return (
                    ((this.activeSeries.series_rating_value - x1) * (y2 - x2)) /
                    (y1 - x1) +
                    x2
                )
            } else {
                return null
            }
        },
        /*
        sumUpcomingBroadcasts() {
            return _.reduce(
                this.future,
                function(sum, n) {
                    return sum + n.items.length
                },
                0
            )
        },
        ...mapGetters('series', {
            future: 'groupedByDate',
        }),*/
        ...mapGetters('program', {
            stations: 'stations',
        }),
        ...mapGetters('program', {
            pDayFromNow: 'pDay',
        }),
        ...mapGetters('series', {
            searchResults: 'search',
        }),
        ...mapGetters('series', {
            userSeries: 'series',
        }),
        ...mapGetters('series', {
            activeSeries: 'first',
        }),

        activeUserSeries() {
            const f = this.userSeries.find(
                item => item.series_id == this.activeSeries.series_id
            )
            if (f !== undefined) {
                return f
            }
            return {}
        },
        activeUserSeriesWhitelist() {
            if (Object.keys(this.activeUserSeries).length) {
                return this.activeUserSeries.whitelist
            }
            return []
        },

        flattenedProgram() {
            if (Object.keys(this.activeStation).length) {
                return this.activeStation.sections
                    .filter(section => section.data.length)
                    .map(item => item.data)
                    .flat()
            }
            return []
        },
        primeTime() {
            return this.flattenedProgram
                .filter(item => {
                    const d = this.dayjs(item.bc_end)
                        .startOf('day')
                        .add(20, 'hours')
                        .add(15, 'minutes')
                    return this.dayjs(item.bc_end).isAfter(d)
                })
                .slice(0, 3)
        },
        activeStation() {
            if (
                this.pDayFromNow.length &&
                this.modals.station.selected != null
            ) {
                const f = this.pDayFromNow.find(
                    item => item.slug == this.modals.station.selected.slug
                )
                if (f !== undefined) {
                    return f
                }
                return {}
            }
            return {}
        },
        favorites: {
            get() {
                return this.$store.state.user.stations.data
            },
            set(value) {
                //
            },
        },
    },
    methods: {
        onFavoritesModalOpen() { },
        onFavoritesModalHidden() {
            this.modals.favorites.search.keyword = ''
            this.modals.favorites.collapsed = true
            this.modals.favorites.hide = false
        },
        onSeriesModalOpen() { },
        onSeriesModalHidden() { },
        onStationModalOpen() { },
        onStationModalHidden() {
            this.modals.station.selected = {}
        },
        onAddSeriesModalHidden() {
            this.modals.add_series.selected = []
            this.modals.add_series.hide = false
            this.modals.add_series.collapsed = true
            this.modals.add_series.search.keyword = ''
            this.modals.add_series.search.loading = false
            this.modals.add_series.search.request = null
        },

        onAdModalHidden() {
            this.nativeBridge(
                this.modals.ad.response.data['fn'],
                this.modals.ad.response.data['params']
            )
        },

        fetchUserStations() {
            this.$store
                .dispatch('user/fetchStations')
                .catch(e => {
                    this.handleAxiosError(e)
                })
        },
        fetchSeries() {
            this.$store
                .dispatch('series/fetch', {
                    v: this.$router.currentRoute.query.v
                })
                .catch(e => {
                    this.handleAxiosError(e)
                })
        },
        fetchProgram() {
            this.$store
                .dispatch('program/fetch')
                .catch(e => {
                    this.handleAxiosError(e)
                })
        },

        adCountDownTimer() {
            if (this.modals.ad.countDown > 0) {
                setTimeout(() => {
                    this.modals.ad.countDown -= 1
                    this.adCountDownTimer()
                }, 1000)
            } else {
                this.modals.ad.show = false
                setTimeout(() => {
                    this.modals.ad.countDown = 3
                }, 500)
            }
        },

        async updateSeriesWhitelist(items) {
            return new Promise((resolve, reject) => {
                this.$store
                    .dispatch('series/update', {
                        data: {
                            id: this.activeSeries.series_id,
                            update: {
                                whitelist: JSON.stringify(items),
                            },
                        },
                    })
                    .catch(e => {
                        this.handleAxiosError(e)
                        reject()
                    })
                    .then(() => {
                        this.nativeBridge('showToast', 'Änderung gespeichert')
                        resolve()
                    })
            })
        },
        async pushUserSeriesWhitelist(item) {
            if (!item.loading) {
                let temp = _.cloneDeep(this.activeUserSeriesWhitelist)
                temp.push(item.value)
                item.loading = true
                await this.updateSeriesWhitelist(temp)
                item.loading = false
            }
        },
        async popUserSeriesWhitelist(item) {
            if (!item.loading) {
                let temp = _.cloneDeep(this.activeUserSeriesWhitelist)
                const idx = temp.indexOf(item.value)
                if (idx > -1) {
                    temp.splice(idx, 1)
                    item.loading = true
                    await this.updateSeriesWhitelist(temp)
                    item.loading = false
                }
            }
        },
        cc(val) {
            this.$router.push({
                name: val,
                query: {
                    history_back: 1,
                },
            })
        },
        confirm(title, description, okFunc) {
            this.$bvModal
                .msgBoxConfirm(description, {
                    title,
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Ja, löschen',
                    cancelTitle: 'Nein, abbrechen',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true,
                })
                .then(value => {
                    if (value) {
                        okFunc()
                    }
                })
        },
        removeFavorite() {
            const idx = this.modals.favorites.selected.findIndex(
                item => item.id == this.modals.favorites.selected.id
            )
            this.modals.favorites.selected.splice(idx, 1)
            this.saveFavorites()
            this.modals.station.show = false
        },
        cancelSearchSeriesRequest() {
            if (this.modals.add_series.search.request !== null)
                this.modals.add_series.search.request.cancel()
        },
        searchSeries() {
            if (this.modals.add_series.search.keyword.length) {
                const axiosSource = axios.CancelToken.source()
                this.modals.add_series.search.request = {
                    cancel: axiosSource.cancel,
                }
                this.modals.add_series.search.loading = true
                this.$store
                    .dispatch('series/search', {
                        data: {
                            keyword: this.modals.add_series.search.keyword,
                        },
                        options: {
                            cancelToken: axiosSource.token,
                        },
                    })
                    .catch(e => {
                        this.handleAxiosError(e)
                    })
                    .then(() => {
                        this.modals.add_series.search.loading = false
                    })
            }
        },

        openStationModal(item) {
            this.modals.station.show = true
            this.modals.station.selected = item
            axios
                .post('/activity/element/open_station_modal', {}, {
                    headers: { Authorization: `Bearer ${this.$store.state.auth.token}` }
                })
                .catch(e => {
                    this.handleAxiosError(e)
                })
        },
        openFavoritesModal(editMode = false) {
            this.modals.favorites.editMode = editMode
            if (editMode) {
                this.modals.favorites.collapsed = false
            }
            this.modals.favorites.show = true
            this.modals.favorites.selected = _.cloneDeep(this.favorites)
        },
        openSeriesModal(item) {
            this.modals.series.loading = true
            this.modals.series.show = true
            this.modals.series.selected = item
            this.$store
                .dispatch('series/show', {
                    data: {
                        series_id: item.series_id,
                    },
                })
                .catch(e => {
                    this.handleAxiosError(e)
                })
                .then(() => {
                    this.modals.series.loading = false
                })
        },
        openAddSeriesModal(editMode = false) {
            this.modals.add_series.editMode = editMode
            if (editMode) {
                this.modals.add_series.collapsed = false
            }
            this.modals.add_series.show = true
            this.modals.add_series.selected = _.cloneDeep(this.userSeries)
        },
        openStreamSourceModal(item) {
            this.modals.stream_source.show = true
            this.modals.stream_source.selected = item
        },
        toggleFavorites(item) {
            let idx = this.modals.favorites.selected.findIndex(
                i => i.id === item.id
            )
            if (idx === -1) {
                this.modals.favorites.selected.push(item)
            } else {
                this.modals.favorites.selected.splice(idx, 1)
            }
        },
        saveFavorites() {
            this.$store.dispatch('user/updateStations', {
                data: {
                    items: this.modals.favorites.selected,
                },
            })
        },
        toggleSeries(item) {
            let idx = this.modals.add_series.selected.findIndex(
                i => i.series_id === item.series_id
            )
            if (idx === -1) {
                this.modals.add_series.selected.push(item)
            } else {
                this.modals.add_series.selected.splice(idx, 1)
            }
        },
        popSeries() {
            let temp = _.cloneDeep(this.userSeries)
            const idx = temp.findIndex(
                item2 => item2.series_id == this.activeSeries.series_id
            )
            temp.splice(idx, 1)
            this.syncSeries(temp)
            this.modals.series.show = false
        },
        syncSeries(items) {
            this.$store.dispatch('series/sync', {
                data: {
                    items:
                        items !== undefined
                            ? items
                            : this.modals.add_series.selected,
                },
            })
        },
        addStation() {
            this.nativeBridge('addStation', this.modals.google.data)
        },
        removeStationUrl() {
            const token = this.$store.state.auth.token
            axios
                .delete(
                    '/user/custom_url?station_slug=' +
                    this.modals.station.selected.slug,
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                )
                .catch(e => {
                    this.handleAxiosError(e)
                })
                .then(response => {
                    this.nativeBridge('showToast', 'Sender-URL entfernt')
                })
        },
        startBroadcast(item) {
            const token = this.$store.state.auth.token
            axios
                .post(
                    '/activity/start-stream',
                    {
                        station_slug: item.slug,
                        //bc_id: item.bc_id,
                        ad_available:
                            localStorage.getItem('ad_available') == 'true',
                    },
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                )
                .then(response => {
                    if (response.data['fn'] == 'addStation') {
                        this.modals.google.show = true
                        this.modals.google.data = response.data['params']
                        this.modals.google.data.url =
                            'https://www.google.de/search?q=' +
                            this.modals.google.data.label +
                            '+stream+ohne+anmeldung'
                    } else {
                        if (response.data['params'].show_ad) {
                            localStorage.setItem('last_ad_at', dayjs())
                            localStorage.setItem('ad_loaded', 0)
                            this.modals.ad.show = true
                            this.modals.ad.response = response
                        } else {
                            this.nativeBridge(
                                response.data['fn'],
                                response.data['params']
                            )
                        }
                    }
                })
                .catch(e => {
                    this.handleAxiosError(e)
                })
        },
    },
    mounted() {
        this.modals.favorites.selected = _.cloneDeep(this.favorites)
        this.modals.add_series.selected = _.cloneDeep(this.userSeries)

        this.debSearchSeries = _.debounce(this.searchSeries, 250)

        this.fetchUserStations()
        this.fetchSeries()
        this.fetchProgram()

        axios
            .post('/activity/router/tv', {}, {
                headers: { Authorization: `Bearer ${this.$store.state.auth.token}` }
            })
            .catch(e => {
                this.handleAxiosError(e)
            })
    },
}