import { mapGetters } from 'vuex'
import Channel from '../components/medialibrary/Channel'
import Loading from '../components/Loading'
export default {
    components: {
        Channel,
        Loading,
    },
    data: function() {
        return {
            dayjs,
            loading: true,
        }
    },
    computed: {
        ...mapGetters('medialibrary', {
            channels: 'channels',
        }),
    },
    methods: {
        fetchChannels() {
            this.loading = true
            this.$store
                .dispatch('medialibrary/channels')
                .catch(e => {
                    this.handleAxiosError(e)
                })
                .then(() => {
                    this.loading = false
                })
        },
    },
    mounted() {
        if (!this.channels.length) {
            this.fetchChannels()
        } else {
            this.loading = false
        }
    },
}
