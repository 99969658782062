import { mapGetters } from 'vuex'
import Broadcast from '../components/Broadcast'
import Loading from '../components/Loading'
import JoynItem from '../components/JoynItem'
export default {
    components: {
        Broadcast,
        Loading,
        JoynItem
    },
    data: function () {
        return {
            hProgram: null || localStorage.getItem('hProgram'),
            search: {
                keyword: '',
                items: [],
                loading: false,
                request: null,
            },
            selectedStation: null,
            selectedDate: null,
            randomJoynItem: {
                slug: "",
                title: "",
                duration: 0
            },
            version: localStorage.getItem('app_version') || '-',
        }
    },
    watch: {
        'search.keyword': {
            handler() {
                if (this.search.loading) this.cancelRequest()
                if (this.search.keyword.length) {
                    this.search.loading = true
                    this.debSearch()
                } else {
                    this.$store.commit('program/UPDATE_PROP', {
                        prop: 'search',
                        value: [],
                    })
                }
            },
        },
        $route: {
            handler() {
                this.search.keyword = ''
            },
        },
    },
    computed: {
        ...mapGetters('program', {
            days: 'dates',
        }),
        ...mapGetters('program', {
            pDayFromNow: 'pDay',
        }),
        ...mapGetters('program', {
            searchResults: 'search',
        }),
        activeStation() {
            if (this.pDayFromNow.length) {
                return this.pDayFromNow.find((item, index) => {
                    return (
                        item.slug == this.stationQuery ||
                        (index == 0 && this.stationQuery == null)
                    )
                })
            } else {
                return {}
            }
        },
        // gagatv
        dateQuery: {
            get: function () {
                if (!this.selectedDate) {
                    return this.days[0] ? this.days[0].date.format('YYYY-MM-DD') : null
                }
                return this.selectedDate ? this.selectedDate : null
            },
            // setter
            set: function (newValue) {
                this.navigateDate(newValue)
            }
        },
        // gagatv
        dateOptions() {
            return this.days.map(item => {
                if (!item.date)
                    return { value: null, text: 'Laden...' }

                if (item.isToday) {
                    return {
                        value: item.date.format('YYYY-MM-DD'),
                        text: item.date.format('dddd') + ", " + item.date.format('DD. MMMM YYYY') + " (heute)"
                    }
                }
                return {
                    value: item.date.format('YYYY-MM-DD'),
                    text: item.date.format('dddd') + ", " + item.date.format('DD. MMMM YYYY')
                };
            })
        },
        stationQuery() {
            return this.selectedStation ? this.selectedStation.slug : null
        },
        loading() {
            return (
                this.$store.state.program.loading ||
                this.$store.state.series.all.loading
            )
        },
        eligableForJoyn() {
            return this.version >= 49;
        },
        everyX() {
            return true;
        }
    },
    methods: {
        onKeyUp() {
            if (this.search.loading) this.cancelRequest()
            if (this.search.keyword.length >= 2) {
                this.debSearch()
            } else {
                this.searchResults = []
            }
        },
        handleBroadcastClick() {
            this.hProgram = 1
            localStorage.setItem('hProgram', this.hProgram)
        },
        navigateDate(value) {
            this.selectedDate = value
            this.fetchProgram()
        },
        navigateStation(item) {
            this.selectedStation = item
        },
        cancelRequest() {
            this.search.request.cancel()
        },
        searchProgram() {
            const axiosSource = axios.CancelToken.source()
            this.search.request = { cancel: axiosSource.cancel }
            this.search.loading = true
            this.$store
                .dispatch('program/search', {
                    data: {
                        keyword: this.search.keyword,
                    },
                    options: {
                        cancelToken: axiosSource.token,
                    },
                })
                .catch(e => {
                    this.handleAxiosError(e)
                })
                .then(() => {
                    this.search.loading = false
                })
        },
        fetchProgram() {
            this.$store
                .dispatch('program/fetch', {
                    data: {
                        station: this.stationQuery,
                        date: this.dateQuery,
                    },
                })
                .catch(e => {
                    this.handleAxiosError(e)
                })
        },
        fetchSeries() {
            this.$store
                .dispatch('series/fetch', {
                    v: this.$router.currentRoute.query.v
                })
                .catch(e => {
                    this.handleAxiosError(e)
                })
        },
        fetchUserStations() {
            this.$store
                .dispatch('user/fetchStations')
                .catch(e => {
                    this.handleAxiosError(e)
                })
        },
        fetchRandomJoynMovie() {
            axios.get("/joyn/random-movie", {
                headers: { Authorization: `Bearer ${this.$store.state.auth.token}` }
            }).then(response => {
                this.randomJoynItem = response.data
            }).catch(console.error)
        }
    },
    mounted() {
        this.debSearch = _.debounce(this.searchProgram, 250)
        this.fetchUserStations()
        this.fetchSeries()
        this.fetchProgram()
        this.fetchRandomJoynMovie();

        axios
            .post('/activity/router/program', {}, {
                headers: { Authorization: `Bearer ${this.$store.state.auth.token}` }
            })
            .catch(e => {
                this.handleAxiosError(e)
            })
    },
    created() {
        window.onscroll = () => {
            let header = document.getElementById('stations')
            if (header) {
                let sticky = header.offsetTop
                if (window.pageYOffset > sticky) {
                    header.classList.add('sticky')
                } else {
                    header.classList.remove('sticky')
                }
            }
        }
    },
}