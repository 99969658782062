<template>
    <div>
        <loading v-if="loading" />
        <div v-else>
            <h5 class="page-title mb-0">Alle Kanäle</h5>
            <p class="text-muted mb-0">
                Entdecke Videos aus {{ channels.length }} Kanälen
            </p>
            <channel
                v-for="(item, index) in channels"
                :key="'channel-' + index"
                :item="item"
                :layout="1"
            />
        </div>
    </div>
</template>
<script>
import MediaLibraryChannels from '../../../views/MediaLibraryChannels'
export default {
    ...MediaLibraryChannels
}
</script>