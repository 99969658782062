<template>
    <div>
        <loading v-if="loading" />
        <div v-else>
            <h5 class="page-title mb-0">Sendungen</h5>
            <div
                v-for="(item, index) in series.filter(
                    (item, index) => index < 20
                )"
                :key="'a-' + index"
            >
                <series :item="item" />
            </div>
        </div>
    </div>
</template>
<script>
import MediaLibrarySeries from '../../../views/MediaLibrarySeries'
export default {
    ...MediaLibrarySeries
}
</script>