import { mapGetters } from 'vuex'
import MediaLibraryItem from '../components/medialibrary/Item'
import Loading from '../components/Loading'
import { EventBus } from '@/event-bus'

export default {
    components: {
        MediaLibraryItem,
        Loading,
    },
    data: function() {
        return {
            dayjs,
            filter: {
                selected: 'latest',
                options: [
                    { value: 'latest', text: 'Neuste Filme' },
                    {
                        value: 'best_rating_7days',
                        text: 'TOP Filme der letzten 7 Tage',
                    },
                    { value: 'best_rating', text: 'TOP Filme von allen' },
                ],
            },
        }
    },
    computed: {
        ...mapGetters('medialibrary', {
            items: 'movies',
        }),
        loading() {
            return (
                this.$store.state.medialibrary.movies.loading &&
                !this.$store.state.medialibrary.movies.append
            )
        },
        appendLoading() {
            return (
                this.$store.state.medialibrary.movies.loading &&
                this.$store.state.medialibrary.movies.append
            )
        },
    },
    methods: {
        onChangeFilter() {
            this.fetchItems(0)
        },
        fetchItems(offset) {
            this.$store
                .dispatch('medialibrary/movies', {
                    offset: offset !== undefined ? offset : 0,
                    sortBy: this.filter.selected,
                })
                .catch(e => {
                    this.handleAxiosError(e)
                })
                .then(() => {})
        },
        onBottomed() {
            if (this.items.length) {
                this.fetchItems(this.items.length)
            }
        },
    },
    mounted() {
        EventBus.$on('bottomed', this.onBottomed)
        if (!this.items.length && !this.appendLoading) {
            this.fetchItems()
        }
    },
    beforeDestroy() {
        EventBus.$off('bottomed', this.onBottomed)
    },
}