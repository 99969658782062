<template>
    <div>
        <b-skeleton width="85%" :variant="variant"></b-skeleton>
        <b-skeleton width="55%" :variant="variant"></b-skeleton>
        <b-skeleton width="70%" :variant="variant"></b-skeleton>
        <b-skeleton width="45%" :variant="variant"></b-skeleton>
    </div>
</template>

<script>

const variants = {
    'gagatv': 'primary',
    'livetv': ''
}
export default {
    name: 'Loading',
    props: {
        layout: {
            type: Number,
            required: false,
        },
    },
    data() {
        return {
            variant: variants[process.env.VUE_APP_VARIANT]
        }
    }
}
</script>
