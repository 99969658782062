import { mapGetters } from 'vuex'
import Series from '../components/medialibrary/Series'
import Loading from '../components/Loading'
export default {
    components: {
        Series,
        Loading,
    },
    data: function() {
        return {
            dayjs,
        }
    },
    computed: {
        ...mapGetters('medialibrary', {
            series: 'series',
        }),
        loading() {
            return this.$store.state.medialibrary.series.loading
        },
    },
    methods: {},
    mounted() {},
}