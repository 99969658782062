import { mapGetters } from 'vuex'
import Broadcast from '../components/Broadcast'
import Loading from '../components/Loading'
export default {
    components: {
        Broadcast,
        Loading,
    },
    data: function () {
        return {
            dayjs,
        }
    },
    computed: {
        ...mapGetters('series', {
            future: 'groupedByDate',
        }),
        sum() {
            return _.reduce(
                this.past,
                function (sum, n) {
                    return sum + n.items.length
                },
                0
            )
        },
    },
    methods: {
        onModalHidden() {
            this.fetchBroadcasts()
        },
        fetchBroadcasts() {
            this.$store
                .dispatch('series/broadcasts')
                .catch(e => {
                    this.handleAxiosError(e)
                })
        },
    },
    mounted() {
        this.fetchBroadcasts()

        axios
            .post('/activity/router/bookmarks', {}, {
                headers: { Authorization: `Bearer ${this.$store.state.auth.token}` }
            })
            .catch(e => {
                this.handleAxiosError(e)
            })
    },
}