import { mapGetters } from 'vuex'
import Broadcast from '../components/Broadcast'
import Loading from '../components/Loading'

export default {
    components: {
        Broadcast,
        Loading,
    },
    data: function () {
        return {
            dayjs,
            day: null,
            days: [...Array(7).keys()].map(i => {
                return dayjs()
                    .startOf('day')
                    .add(i, 'days')
            }),
            hTvMovies: null || localStorage.getItem('hTvMovies'),
            joynItems: [],
            joynSelected: false,
        }
    },
    computed: {
        ...mapGetters('series', {
            future: 'recommendationsGroupedByDate',
        }),
        // gagatv
        dateQuery: {
            get: function () {
                if (!this.day) {
                    return this.days[0] ? this.days[0].format('YYYY-MM-DD') : null
                }
                return this.day ? this.day : null
            },
            // setter
            set: function (newValue) {
                this.day = newValue
                this.$store.state.series.recommendations.selector = this.day
                this.fetchRecommendations()
            }
        },
        // gagatv
        dateOptions() {
            return this.days.map(item => {
                if (!item)
                    return { value: null, text: 'Laden...' }

                if (item.isSame(new Date(), 'day')) {
                    return {
                        value: item.format('YYYY-MM-DD'),
                        text: item.format('dddd') + ", " + item.format('DD. MMMM YYYY') + " (heute)"
                    }
                }
                return {
                    value: item.format('YYYY-MM-DD'),
                    text: item.format('dddd') + ", " + item.format('DD. MMMM YYYY')
                };
            })
        },
    },
    methods: {
        handleJoynItemClick(item) {
            this.startJoyn(item, 'gagatv_recommendations');
        },
        hideHint() {
            this.hTvMovies = 1
            localStorage.setItem('hTvMovies', this.hTvMovies)
        },
        navigateDate(item) {
            this.day = item.format('YYYY-MM-DD')
            this.$store.state.series.recommendations.selector = this.day
            this.fetchRecommendations()
            this.joynSelected = false;
        },
        fetchRecommendations() {
            this.$store
                .dispatch('series/recommendations', {
                    date: this.day,
                })
                .catch(e => {
                    this.handleAxiosError(e)
                })
        },
        fetchSeries() {
            this.$store
                .dispatch('series/fetch', {
                    v: this.$router.currentRoute.query.v
                })
                .catch(e => {
                    this.handleAxiosError(e)
                })
        },
        fetchRandomMovie() {
            axios.get("/joyn/random-movie", {
                headers: { Authorization: `Bearer ${this.$store.state.auth.token}` }
            }).then(response => {
                console.log(response.data);
                this.joynItems = [response.data]
            }).catch(console.error)
        }
    },
    mounted() {
        this.$store.state.series.recommendations.selector = null
        this.fetchRecommendations()
        this.fetchSeries()

        if (process.env.VUE_APP_VARIANT === "gagatv") {
            this.fetchRandomMovie()
        }

        axios
            .post('/activity/router/recommendations', {}, {
                headers: { Authorization: `Bearer ${this.$store.state.auth.token}` }
            })
            .catch(e => {
                this.handleAxiosError(e)
            })
    },
}
