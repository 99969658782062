import Vue from 'vue'
const CACHE_LIFETIME = 3600

const state = {
    settings: {
        wifi_only: false,
        movie_notifications: true,
    },
    created_at: null,
    subscription: 0,
    notify_premium: false,
    stations: {
        loading: true,
        created_at: null,
        data: [],
        expired_at: null,
    }
}
const getters = {}
const actions = {
    async fetchSettings(context, payload) {
        const token = context.rootState.auth.token
        return new Promise((resolve, reject) => {
            axios
                .get('/user/settings?api_token=' + token)
                .then(response => {
                    context.state.settings.wifi_only = !!response.data.wifi_only;
                    context.state.settings.movie_notifications = !!response.data.movie_notifications;
                    context.state.subscription = response.data.subscription;
                    context.state.notify_premium = response.data.notify_premium;
                    context.state.created_at = response.data.created_at;
                    resolve()
                })
                .catch(e => {
                    reject(e)
                })
        })
    },
    async fetchStations(context, payload) {
        const scope = 'stations'
        const token = context.rootState.auth.token
        return new Promise((resolve, reject) => {
            if (dayjs().isBetween(context.state[scope].created_at, context.state[scope].expired_at)) {
                // cached
                resolve()
            } else {
                context.state[scope].loading = true
                axios
                    .get('/user/station?api_token=' + token)
                    .then(response => {
                        context.commit('UPDATE_PROP2', {
                            prop: 'stations',
                            value: response.data
                        })
                        resolve()
                    })
                    .catch(e => {
                        reject(e)
                    })
            }
        })
    },
    async updateSettings(context) {
        const token = context.rootState.auth.token
        return new Promise((resolve, reject) => {
            axios
                .put('/user/settings?api_token=' + token, context.state.settings, {
                    headers: { Authorization: `Bearer ${token}` }
                })
                .then(response => {
                    context.state.settings.wifi_only = !!response.data.wifi_only;
                    context.state.settings.movie_notifications = !!response.data.movie_notifications;
                    resolve()
                })
                .catch(e => {
                    reject(e)
                })
        })
    },
    async updateStations(context, payload) {
        const token = context.rootState.auth.token
        return new Promise((resolve, reject) => {
            axios
                .put('/user/station?api_token=' + token, payload.data, {
                    headers: { Authorization: `Bearer ${token}` }
                })
                .then(response => {
                    context.commit('UPDATE_PROP2', {
                        prop: 'stations',
                        value: response.data
                    })
                    resolve()
                })
                .catch(e => {
                    reject(e)
                })
        })
    },
}
const mutations = {
    UPDATE_PROP(state, payload) {
        state[payload.prop] = payload.value;
    },
    UPDATE_PROP2(state, payload) {
        state[payload.prop].created_at = dayjs().toString()
        state[payload.prop].expired_at = dayjs().add(CACHE_LIFETIME, 's').toString()
        state[payload.prop].data = payload.value
        state[payload.prop].loading = false
    },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
