import Loading from '../components/Loading'
import ImageItem from '../components/ImageItem'
import JoynModal from '../components/JoynModal.vue'
import axios from 'axios'

export default {
    components: {
        Loading,
        ImageItem,
        JoynModal
    },
    data: function () {
        return {
            withoutRegistrationState: false || localStorage.getItem('withoutRegistration') === 'true',
            hJoynEveryDay: null || localStorage.getItem('hJoynEveryDay'),
            loading: true,
            items: [],
            watchedItems: [],
            categories: [
                { label: '🔥 Beliebt', slug: 'top' },
                { label: '🚀 Neu', slug: 'new' },

                { label: 'Action', slug: 'action-abenteuer' },
                { label: 'Komödie', slug: 'komoedie' },
                { label: 'Drama', slug: 'drama' },
                { label: 'Horror', slug: 'horror' },
                { label: 'Krimi', slug: 'krimi' },
            ],
            selectedCategorySlug: 'top',
            selectedMovie: {},
            selectedMovieModal: false,
            modal: {
                movie: {},
                show: false,
            }
        }
    },
    methods: {
        handleJoynItemClick(item) {
            this.selectedMovie = item;
            this.selectedMovieModal = true;
            //this.startJoyn(item, 'movies');
        },
        fetchMovies(category) {
            this.loading = true
            axios.get(`/joyn/categories_neu/${category}?without_registration=${!this.withoutRegistrationState}`, {
                headers: { Authorization: `Bearer ${this.$store.state.auth.token}` }
            }).then(response => {
                this.loading = false;
                this.items = response.data
            }).catch(console.error)
        },
        fetchMovie(slug) {
            this.loading = true
            axios.get(`/joyn/movies/${slug}?notification=1`, {
                headers: { Authorization: `Bearer ${this.$store.state.auth.token}` }
            }).then(response => {
                this.loading = false;
                this.modal.show = true
                this.modal.movie = response.data
            }).catch(console.error)
        },
        selectCategory(slug) {
            this.fetchMovies(slug)
            axios
                .post(`/activity/router/joyn-${slug}`, {}, {
                    headers: { Authorization: `Bearer ${this.$store.state.auth.token}` }
                })
                .catch(this.handleAxiosError)
            this.selectedCategorySlug = slug
        },
        hideHint() {
            this.hJoynEveryDay = 1
            localStorage.setItem('hJoynEveryDay', this.hJoynEveryDay)
        },
    },
    computed: {
        withoutRegistration: {
            get: function () {
                return this.withoutRegistrationState
            },
            set: function (newValue) {
                localStorage.setItem('withoutRegistration', newValue);
                this.withoutRegistrationState = newValue;
                this.fetchMovies(this.selectedCategorySlug);
            },
        },
    },
    mounted() {
        this.fetchMovies(this.selectedCategorySlug)

        const slug = this.$router.currentRoute.query.slug;
        if (slug) {
            this.fetchMovie(slug)
        }

        axios.get("/joyn/watched", {
            headers: { Authorization: `Bearer ${this.$store.state.auth.token}` }
        }).then(response => {
            this.watchedItems = response.data
        }).catch(console.error)

        axios
            .post('/activity/router/joyn', {}, {
                headers: { Authorization: `Bearer ${this.$store.state.auth.token}` }
            })
            .catch(this.handleAxiosError)
    },
}
