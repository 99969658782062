import { mapGetters } from 'vuex'
import MediaLibraryItem from '../components/medialibrary/Item'
import Channel from '../components/medialibrary/Channel'
import Loading from '../components/Loading'
import { EventBus } from '@/event-bus'
import ImageItem from '../components/ImageItem'
import JoynModal from '../components/JoynModal.vue'

export default {
    components: {
        Channel,
        MediaLibraryItem,
        Loading,
        ImageItem,
        JoynModal
    },
    data: function () {
        return {
            hMediaLibrary: null || localStorage.getItem('hMediaLibrary'),
            dayjs,
            modals: {
                genre: {
                    loading: true,
                    show: false,
                    selected: {},
                },
            },
            joynItems: [],
            selectedMovie: {},
            selectedMovieModal: false,
        }
    },
    computed: {
        ...mapGetters('medialibrary', {
            latest: 'latest',
        }),
        ...mapGetters('medialibrary', {
            recommendations: 'recommendations',
        }),
        ...mapGetters('medialibrary', {
            movies: 'movies',
        }),
        ...mapGetters('medialibrary', {
            channels: 'channels',
        }),
        ...mapGetters('medialibrary', {
            genres: 'genres',
        }),
        ...mapGetters('medialibrary', {
            genreItems: 'genreItems',
        }),
        loading() {
            const state = this.$store.state.medialibrary

            return (
                state.channels.loading ||
                state.genres.loading ||
                state.recommendations.loading ||
                state.latest.loading
            )
        },
        genreItemsLoading() {
            return (
                this.$store.state.medialibrary.genreItems.loading &&
                !this.$store.state.medialibrary.genreItems.append
            )
        },
        appendGenreItemsLoading() {
            return (
                this.$store.state.medialibrary.genreItems.loading &&
                this.$store.state.medialibrary.genreItems.append
            )
        },
    },
    watch: {
        $route(to, from) { },
    },
    methods: {
        handleJoynItemClick(item) {
            this.selectedMovie = item;
            this.selectedMovieModal = true;
            //this.startJoyn(item, 'medialibrary_home');
        },
        hideHint() {
            this.hMediaLibrary = 1
            localStorage.setItem('hMediaLibrary', this.hMediaLibrary)
        },
        to(name) {
            this.$router.push({ name: name })
        },
        openGenreModal(item) {
            this.modals.genre.selected = item
            this.modals.genre.show = true

            this.fetchGenreItems()
        },
        fetchGenreItems(offset) {
            this.$store
                .dispatch('medialibrary/genreItems', {
                    genreId: this.modals.genre.selected.id,
                    offset: offset !== undefined ? offset : 0,
                })
                .catch(e => {
                    this.handleAxiosError(e)
                })
                .then(() => { })
        },
        onModalBottomed() {
            //console.log('onModalBottomed')
            if (this.genreItems.length && !this.appendGenreItemsLoading) {
                this.fetchGenreItems(this.genreItems.length)
            }
        },
    },
    mounted() {
        EventBus.$on('modal-bottomed', this.onModalBottomed)

        axios.get("/joyn/movies", {
            headers: { Authorization: `Bearer ${this.$store.state.auth.token}` }
        }).then(response => {
            console.log(response.data);
            this.joynItems = response.data
        }).catch(console.error)

    },
    beforeDestroy() {
        EventBus.$off('modal-bottomed', this.onModalBottomed)
    },
}