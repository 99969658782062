
import { mapGetters } from 'vuex'
import Broadcast from '../components/Broadcast'
import Loading from '../components/Loading'
import JoynItem from '../components/JoynItem'

export default {
    components: {
        Broadcast,
        Loading,
        JoynItem
    },
    data: function () {
        return {
            dayjs,
            hToday2015: null || localStorage.getItem('hToday2015'),
            selected: 'p2015',
            randomJoynItem: {
                slug: "",
                title: "",
                duration: 0
            },
            version: localStorage.getItem('app_version') || '-',
        }
    },
    computed: {
        ...mapGetters('program', {
            p2015: 'p2015',
        }),
        ...mapGetters('program', {
            p2200: 'p2200',
        }),
        ...mapGetters('program', {
            p2300: 'p2300',
        }),
        rows() {
            if (this.selected == 'p2015') {
                return this.p2015.filter(item => !item.finished)
            }
            if (this.selected == 'p2200') {
                return this.p2200.filter(item => !item.finished)
            }
            if (this.selected == 'p2300') {
                return this.p2300.filter(item => !item.finished)
            }
            return []
        },
        eligableForJoyn() {
            return this.version >= 49;
        },
        everyX() {
            return true;
        }
    },
    methods: {
        handleClick() {
            this.hToday2015 = 1
            localStorage.setItem('hToday2015', this.hToday2015)
        },
        fetchProgram() {
            this.$store
                .dispatch('program/fetch')
                .catch(e => {
                    this.handleAxiosError(e)
                })
        },
        fetchUserStations() {
            this.$store
                .dispatch('user/fetchStations')
                .catch(e => {
                    this.handleAxiosError(e)
                })
        },
        fetchSeries() {
            this.$store
                .dispatch('series/fetch', {
                    v: this.$router.currentRoute.query.v
                })
                .catch(e => {
                    this.handleAxiosError(e)
                })
        },
        fetchRandomJoynMovie() {
            axios.get("/joyn/random-movie", {
                headers: { Authorization: `Bearer ${this.$store.state.auth.token}` }
            }).then(response => {
                this.randomJoynItem = response.data
            }).catch(console.error)
        }
    },
    mounted() {
        this.fetchUserStations()
        this.fetchProgram()
        this.fetchSeries()
        this.fetchRandomJoynMovie()

        axios
            .post('/activity/router/2015', {}, {
                headers: { Authorization: `Bearer ${this.$store.state.auth.token}` }
            })
            .catch(e => {
                this.handleAxiosError(e)
            })

        if (
            dayjs().isBetween(
                dayjs()
                    .startOf('day')
                    .add(5, 'hours'),
                dayjs()
                    .startOf('day')
                    .add(23, 'hours')
            )
        ) {
            this.selected = 'p2200'
        }
        if (
            dayjs().isBetween(
                dayjs()
                    .startOf('day')
                    .add(5, 'hours'),
                dayjs()
                    .startOf('day')
                    .add(22, 'hours')
            )
        ) {
            this.selected = 'p2015'
        }

        if (
            dayjs().isAfter(
                dayjs()
                    .startOf('day')
                    .add(23, 'hours')
            )
        ) {
            this.selected = 'p2300'
        }
    },
}