<template>
    <b-modal
        v-model="modal.show"
        @hidden="adModalHidden()"
        @shown="adCountDownTimer()"
        no-close-on-backdrop
        centered
        body-class="d-flex align-items-center justify-content-center"
        hide-header
        hide-footer
        no-fade
    >
        <div class="text-center">
            <font-awesome-icon style="font-size: 50px" :icon="['fas', 'ad']" />
            <h2>Werbung beginnt in</h2>
            <h2>{{ countDown }}s</h2>
        </div>
    </b-modal>
</template>

<script>
import { EventBus } from '@/event-bus'
export default {
    name: 'Ad',
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        response: {
            type: Object,
            required: true,
            default: () => {},
        },
    },
    data() {
        return {
            modal: {
                show: false,
            },
            countDown: 3,
        }
    },
    watch: {
       	value: function(n) {  
            if(n) {
                
                this.modal.show = n // only temp
                if(this.response.data['params'].show_premium_hint) {
                    //this.proHintModal()
                } else {
                    this.modal.show = n
                }
            }
        }
    },
    computed: {},
    methods: {
        adModalHidden() {
            this.$emit('input', false)
            this.nativeBridge(
                this.response.data['fn'],
                this.response.data['params']
            )
        },
        adCountDownTimer() {
            if (this.countDown > 0) {
                setTimeout(() => {
                    this.countDown -= 1
                    this.adCountDownTimer()
                }, 1000)
            } else {
                this.modal.show = false
                setTimeout(() => {
                    this.countDown = 3
                }, 500)
            }
        },
        proHintModal() {
            this.$bvModal.msgBoxConfirm('Möchtest du die Werbung für eine einmalige Zahlung dauerhaft entfernen?', {
                title: 'Werbung entfernen?',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'primary',
                okTitle: 'JA, Werbung entfernen',
                cancelTitle: 'NEIN',
                footerClass: 'p-2',
                hideHeaderClose: true,
                centered: true
            })
            .then(value => {
                if(value === true) {
                    EventBus.$emit('onPremiumRequested')
                    this.modal.show = false
                    this.$emit('input', false)
                } else {
                    this.modal.show = true
                }
            })
        }          
    },
}
</script>
