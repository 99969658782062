import { mapGetters } from 'vuex'
import MediaLibraryItem from '../components/medialibrary/Item'
import Loading from '../components/Loading'
import { EventBus } from '@/event-bus'

export default {
    components: {
        MediaLibraryItem,
        Loading,
    },
    data: function() {
        return {
            dayjs,
        }
    },
    computed: {
        ...mapGetters('medialibrary', {
            items: 'recommendations',
        }),
        loading() {
            return (
                this.$store.state.medialibrary.recommendations.loading &&
                !this.$store.state.medialibrary.recommendations.append
            )
        },
        appendLoading() {
            return (
                this.$store.state.medialibrary.recommendations.loading &&
                this.$store.state.medialibrary.recommendations.append
            )
        },
    },
    methods: {
        fetchItems(offset) {
            this.$store
                .dispatch('medialibrary/recommendations', {
                    offset: offset !== undefined ? offset : 0,
                })
                .catch(e => {
                    this.handleAxiosError(e)
                })
                .then(() => {})
        },
        onBottomed() {
            if (this.items.length && !this.appendLoading) {
                this.fetchItems(this.items.length)
            }
        },
    },
    mounted() {
        EventBus.$on('bottomed', this.onBottomed)
        if (!this.items.length) {
            this.fetchItems()
        }
    },
    beforeDestroy() {
        EventBus.$off('bottomed', this.onBottomed)
    },
}