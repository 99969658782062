import at from './translations/at';
import en from './translations/en';
import es from './translations/es';
import fr from './translations/fr';
import inL from './translations/in';
import it from './translations/it';
import jp from './translations/jp';
import kr from './translations/kr';
import us from './translations/us';
export default {
  at,
  en,
  es,
  fr,
  in: inL,
  it,
  jp,
  kr,
  us,
};