<template>
    <figure class="image__wrapper">
        <!-- v-lazyload  -->
        <div class="watermark" v-if="isYoutubeThumbnail">
            <img src="../assets/youtube-logo-white.png" width="100%" />
        </div>
        <div class="watermark watermark-tmdb" v-if="isImdbThumbnail">
            <img src="../assets/tmdb-2.png" width="100%" class="rounded" />
        </div>
        <b-img-lazy
            v-bind="mainProps"
            :show="index < 10"
            :src="finalSource"
            class="image__item rounded"
            :style="{ height: height }"
        />
    </figure>
</template>

<script>
//import ImageSpinner from './ImageSpinner'

export default {
    name: 'ImageItem',
    components: {
        //ImageSpinner,
    },
    props: {
        height: {
            type: String,
            required: false,
            default: 'auto',
        },
        index: {
            type: Number,
            required: true,
        },
        source: {
            //type: String,
            required: true,
        },
    },
    data() {
        return {
            mainProps: {
                center: true,
                fluidGrow: true,
                blank: true,
                blankColor: '#bbb',
                width: 600,
                height: 400,
            },
        }
    },
    computed: {
        finalSource() {
            if (this.source != null) {
                return this.source.replace('@._V1_.', '@._V1_UX400_.')
            } else {
                return 'https://www.jomabe.de/placeholder-image.webp'
            }
        },
        isYoutubeThumbnail() {
            const re = /ytimg\.com/gm
            const m = re.exec(this.source)
            return m === null ? false : true
        },
        isImdbThumbnail() {
            const re = /image\.tmdb\.org/gm
            const m = re.exec(this.source)
            return m === null ? false : true
        },
    },
}
</script>

<style scoped lang="scss">
.test {
    /*
    background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        rgba(0, 0, 0, 0.5)
    );*/
    background-size: cover;
    color: white;
    padding: 20px;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1001;
    span {
        font-weight: bold;
        font-size: 12px;
        text-transform: uppercase;
        position: absolute;
        bottom: 0px;
        left: 0px;
        padding: 5px;
        width: 100%;
        background: rgba(0, 0, 0, 0.65);
    }
}

.watermark {
    position: absolute;
    bottom: 6px;
    right: 6px;
    z-index: 11;
    width: 33%;
    &-tmdb {
        width: 50%;
    }
}
.image {
    &__wrapper {
        margin-bottom: 0;
        margin: 0 !important;
        width: 100%;
        position: relative;
    }

    &__item {
        width: 100%;
        height: auto;
        position: relative;
        text-indent: -999em;
        line-height: 0;
        object-fit: cover;
        /*
        &::after {
            content: '';
            display: block;
            background-color: white;
            background-image: url('https://www.jomabe.de/placeholder-image.webp');
            background-position: center;
            background-size: cover;
            padding: 33% 0; // assumes 1:1 image aspect ratio
            position: relative;
        }*/
    }
}
</style>
